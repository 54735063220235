<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_inline_block">
          <div class="mainList_operation_search">
            <span
              >项目名称：
              <el-select
                v-model="parameter.projectId"
                @change="dataUpdate"
                filterable
                clearable
                class="ipt_width"
                placeholder="请输入或选择项目名"
                style="margin-right: 15px"
              >
                <el-option
                  v-for="item in projectList"
                  :key="item.id"
                  :label="item.projectName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </span>

            <span
              >状态：
              <el-select
                class="ipt_width"
                v-model="parameter.status"
                placeholder="请选择状态"
                clearable
                style="margin-right: 15px"
              >
                <el-option
                  v-for="item in performanceType"
                  :label="item.dictName"
                  :value="item.dictVal"
                  :key="item.createdDate"
                ></el-option>
              </el-select>
            </span>

            <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)"
              >搜索</el-button
            >
            <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
          </div>
        </div>
        <div class="fr">
          <el-button type="primary" @click="dialogVisible = true">填报流程</el-button>
        </div>
      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-table ref="multipleTable" border :data="tableData" v-loading="loading">
            <el-table-column align="center" type="index" label="序号" width="50"> </el-table-column>
            <el-table-column
              align="center"
              prop="projectName"
              min-width="320"
              :show-overflow-tooltip="false"
              label="项目名称"
            >
            </el-table-column>
            <el-table-column
              align="center"
              width="90"
              prop="submitName"
              label="提交人"
            ></el-table-column>
            <el-table-column align="center" sortable width="110" prop="submitDate" label="提交时间">
              <template slot-scope="scope">
                {{ scope.row.submitDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column align="center" sortable width="160" prop="status" label="状态">
              <template slot-scope="scope">
                {{ scope.row.status | dict(performanceType) }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="productPerformance" label="生产绩效(元)">
              <template slot-scope="scope">
                {{ scope.row.productPerformance | thousands }}
              </template>
            </el-table-column>
            <el-table-column align="center" width="70" fixed="right" label="操作">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  @click="edit(scope.row)"
                  class="text_Details_Bgc"
                  size="small"
                  >详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="parameter.total"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>

    <el-dialog title="生产绩效方案填报流程图" :visible.sync="dialogVisible" width="65%">
      <el-image style="width: 100%; height: 100%" :src="require('@/assets/生产绩效填报流程图.png')">
      </el-image>
    </el-dialog>

    <Edit
      :beforeOneType="parameter.statusType"
      :isShow.sync="showEditDialog"
      :options="options"
      v-if="showEditDialog"
    ></Edit>
  </div>
</template>
<script>
import { initTreeData } from '@/util/common'
import { mapState } from 'vuex'
export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    Edit: () => import('../Edit'),
  },
  data() {
    return {
      parameter: {
        pageNow: 1,
        pageSize: 50,
        total: 0,
      },
      pageSize: 0,
      performanceType: [],
      tableData: [],
      // deptData: [],
      loading: false,
      dialogTableVisible: false,
      dialogVisible: false,
      showEditDialog: false,
      projectList: [],
      options: {},
    }
  },
  filters: {
    thousands(a) {
      if (a == null || a == '' || a == undefined) {
        return 0
      } else {
        a = Number(a).toLocaleString()
        return a
      }
    },
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  provide() {
    return {
      refresh: this.refresh,
    }
  },
  watch: {},
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.getData()
    this.getProject()

    this.$api.dict
      .listSysDictData('PRODUCT_PERFORMANCE_STATUS', true)
      .then(res => {
        this.performanceType = res.data
      })
      .catch(err => {
        console.log(err)
      })

    // this.$api.sysDept
    //   .listDept()
    //   .then((res) => {
    //     this.deptData = res.data;
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  },
  methods: {
    handleReset() {
      this.parameter.status = ''
      this.parameter.projectId = ''
      this.getData() /** 加载 "付款申请审核" 数据 **/
    },
    refresh() {
      this.showEditDialog = false
      this.pageChangeHandler(1)
    },
    edit(row, i) {
      this.options = row.deepClone()
      this.options.editIndex = 3
      this.showEditDialog = true
    },
    getProject() {
      this.$api.project
        .projectList({
          pageSize: 10000,
          pageNow: 1,
        })
        .then(res => {
          this.projectList = res.data ? res.data : []
        })
        .catch(err => {
          console.log(err)
        })
    },
    dataUpdate(row) {
      this.$forceUpdate()
    },
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },
    getData() {
      this.loading = true
      this.$api.productionControl
        .productPerformanceAllocationList(this.parameter)
        .then(res => {
          this.loading = false
          this.tableData = res.data.records
          this.parameter.total = res.data.total
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
.Total_amount {
  display: inline-block;
  width: 15%;
  line-height: 40px;
  padding-left: 8px;
  border: 1px solid #dcdfe6;
  border-radius: 5px;
}
</style>
